<template>
<div class="card card-success mt-3">
    <div class="card-header" style="height: 52px">
        <h5>Thêm mới đơn hàng</h5>
    </div>
    <!--  BEGIN CONTENT AREA  -->

    <div class="card-body">
        <div class="widget-content widget-content-area">
            <form method="post" v-on:submit.prevent="onSubmitForm">
                <h4>Đơn hàng</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Họ tên</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="name" placeholder="" v-model="order.name" :class="{ error: nameError.status, success: nameSuccess.status }">
                                <p class="text-error" v-if="nameError.status">{{ nameError.text }}</p>
                                <p class="success-text" v-if="nameSuccess.status">{{ nameSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Email</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="email" placeholder="" v-model="order.email" :class="{ error: emailError.status, success: emailSuccess.status }">
                                <p class="text-error" v-if="emailError.status">{{ emailError.text }}</p>
                                <p class="success-text" v-if="emailSuccess.status">{{ emailSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số ĐT</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="phone" placeholder="" v-model="order.phone" :class="{ error: phoneError.status, success: phoneSuccess.status }">
                                <p class="text-error" v-if="phoneError.status">{{ phoneError.text }}</p>
                                <p class="success-text" v-if="phoneSuccess.status">{{ phoneSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Địa chỉ</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="address" placeholder="" v-model="order.address" :class="{ error: addressError.status, success: addressSuccess.status }">
                                <p class="text-error" v-if="addressError.status">{{ addressError.text }}</p>
                                <p class="success-text" v-if="addressSuccess.status">{{ addressSuccess.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Ghi chú</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <input type="text" class="form-control" id="note" placeholder="" v-model="order.note" :class="{ error: noteError.status, success: noteSuccess.status }">
                                <p class="text-error" v-if="noteError.status">{{ noteError.text }}</p>
                                <p class="success-text" v-if="noteSuccess.status">{{ noteSuccess.text }}
                                </p>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Phương thức thanh toán</label>
                            <div class="col-xl-8 col-lg-8 col-sm-8">
                                <select class="form-control  basic" v-model="order.paymentMethod" id="status">
                                    <option value="" selected disabled>Chọn
                                    </option>
                                    <option value="COD">Thanh toán COD
                                    </option>
                                    <option value="Chuyển khoản">Thanh toán chuyển khoản
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-4 col-sm-4 col-sm-4 col-form-label">Tài khoản khách hàng</label>
                            <div class="col-xl-4 col-lg-4 col-sm-4">
                                <input type="text" class="form-control" placeholder="Tìm kiếm khách hàng">
                            </div>
                            <div class="col-xl-4 col-lg-4 col-sm-4">
                                <select class="form-control basic" name="" id="accountUserId" v-model="order.accountUserId">
                                    <option value="" selected disabled>Chọn</option>
                                    <option v-for="item in account" :key="item.id" v-bind:value="item.id">
                                        {{ item.name + " " + item.phone}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <h4>Sản phẩm</h4>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Sản phẩm</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="productId" v-model="orderDetails.productId" @change="fetchProductDetails">
                                    <option value="" selected disabled>Chọn</option>
                                    <option v-for="item in product" :key="item.id" v-bind:value="item.id">
                                        {{ item.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4" v-if="itemleght > 0">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Hậu tố</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="brand" v-model="orderDetails.brand">
                                    <option value="" disabled>Chọn</option>
                                    <option v-for="item in itempro" :key="item.id" v-bind:value="item.valueAttributeName">
                                        {{ item.valueAttributeName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Quà tặng </label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="gift" v-model="orderDetails.gift">
                                    <option value="" disabled>Chọn</option>
                                    <option v-for="item in gifts" :key="item.id" v-bind:value="item.name">{{
                                                        item.name
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-3 col-sm-2 col-sm-3 col-form-label">Voucher </label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <select class="form-control basic" name="" id="discountVoucher" v-model="orderDetails.discountVoucher">
                                    <option value="0" selected disabled>Chọn</option>
                                    <option v-for="item in voucher" :key="item.id" v-bind:value="item.discount">{{
                                                        item.voucherCode + " " + "Discount: " + item.discount + " VNĐ"
                                                    }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- <input type="text" class="form-control" id="quantity" placeholder="" v-model="order.quantity" :class="{ error: quantityError.status, success: quantitySuccess.status }"> -->
                    <!-- <p class="text-error" v-if="quantityError.status">{{ quantityError.text }}</p>
                                <p class="success-text" v-if="quantitySuccess.status">{{ quantitySuccess.text }}
                                </p> -->
                    <div class="col-md-6">
                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Số lượng</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="number" class="form-control" id="quantity" placeholder="Nhập số lượng" v-model="orderDetails.quantity">
                            </div>
                        </div>

                        <div class="form-group row mb-4">
                            <label for="hPassword" class="col-xl-2 col-sm-3 col-sm-2 col-form-label">Tổng giá</label>
                            <div class="col-xl-9 col-lg-9 col-sm-9">
                                <input type="text" class="form-control" id="price" disabled placeholder="Giá tổng" v-model="orderDetails.price">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-10">
                        <button type="button" class="btn btn-info mt-3 mb-3" @click="addToTempList()">+</button>
                    </div>
                    <div class="col-md-12" v-if="prolist.length>0">
                        <h5>Danh sách sản phẩm</h5>
                        <div class="row mt-2">
                            <div class="card-body table-responsive">
                                <table class="table table-head-fixed text-pre-line table-bordered table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Tên sản phẩm
                                            </th>
                                            <th>
                                                Quà tặng hoặc voucher
                                            </th>
                                            <th>
                                                Số lượng
                                            </th>
                                            <th>
                                                Màu hoặc kích thước
                                            </th>
                                            <th>
                                                Thương hiệu
                                            </th>
                                            <th>
                                                Giá
                                            </th>
                                            <th>
                                                Tổng
                                            </th>
                                            <th>

                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr v-for="(item, index) in prolist" :key="item.id">
                                            <td>
                                                {{ index +=1 }}
                                            </td>
                                            <td>
                                                {{ item.productName }}
                                            </td>
                                            <td>
                                                {{ item.gift }} Voucher: {{item.discountVoucher}} VNĐ
                                            </td>
                                            <td>
                                                {{ item.quantity }}
                                            </td>
                                            <td>
                                                {{ item.brand }}
                                            </td>
                                            <td>
                                                Spectra
                                            </td>
                                            <td>
                                                {{ formatPrice(item.price ) }} VNĐ
                                            </td>
                                            <td>
                                                {{ formatPrice((item.price * item.quantity) - item.discountVoucher ) }} VNĐ
                                            </td>
                                            <td>
                                                <button type="button" class="btn btn-danger" @click="removeproductList(i)">Xóa</button>
                                            </td>
                                        </tr>
                                        <tr style="text-align: right;">
                                            <td colspan="6"> <b>Phí ship:</b> 0 VND</td>
                                        </tr>
                                        <tr style="text-align: right;">
                                            <!-- <td colspan="6"> <b>Tổng tiền:</b>{{ formatPrice(item.price) }} VNĐ</td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <!-- <div class="col-lg-5" v-for="(item, i) in prolist" :key="i" style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;margin-right:10px;margin-bottom:10px;padding: 10px;">
                                <p>Sản phẩm: {{item.productName}}</p>
                                <p v-if="item.brand">Hậu tố: {{item.brand}}</p>
                                <p>Số lượng: {{item.quantity}}</p>
                                <p>Giá: {{item.price}} VNĐ</p>
                                <p>Quà tặng: {{item.gift}}</p>
                                <p v-if="item.discountVoucher > 0">Voucher: {{item.discountVoucher}} VNĐ</p>
                                
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-sm-10">
                        <button type="submit" class="btn btn-primary mt-3">Thêm mới</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
</template>

<script>
// import OrderDetailService from '@/services/OrderDetailService'
// import OrderServices from '@/services/OrderServices';
import ProductService from '@/services/ProductService';
import AccountUserService from "@/services/AccountUserService.js";
import GiftService from '@/services/GiftService';
import ItemService from '@/services/ItemService';
import VoucherService from '@/services/VoucherService';

import {
    createToast
} from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
export default {
    data() {
        return {
            message: "",
            currentImage: undefined,
            orders: null,
            url: null,
            ID: null,
            product: null,
            possible: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890",
            lengthOfCode: 8,
            gifts: null,
            itempro: null,
            itemleght: null,
            voucher: null,
            account: null,
            prolist: [],
            order: {
                id: null,
                code: "",
                name: "",
                email: "",
                phone: "",
                note: "",
                address: "",
                paymentMethod: "",
                totalQuantity: "",
                totalAmount: "",
                website: 2,
                accountUserId: "",
                status: true
            },
            orderDetails: {
                id: null,
                productId: "",
                quantity: "",
                gift: "",
                brand: "",
                price: "",
                discountVoucher: 0,
                status: true,
                orderId: ""

            },
            selectedProduct: null,
            nameError: {
                text: "",
                status: false,
            },
            nameSuccess: {
                text: "",
                status: false,
            },
            emailError: {
                text: "",
                status: false,
            },
            emailSuccess: {
                text: "",
                status: false,
            },
            phoneError: {
                text: "",
                status: false,
            },
            phoneSuccess: {
                text: "",
                status: false,
            },
            noteError: {
                text: "",
                status: false,
            },
            noteSuccess: {
                text: "",
                status: false,
            },
            addressError: {
                text: "",
                status: false,
            },
            addressSuccess: {
                text: "",
                status: false,
            },
            paymentMethodError: {
                text: "",
                status: false,
            },
            paymentMethodSuccess: {
                text: "",
                status: false,
            },
            totalQuantityError: {
                text: "",
                status: false,
            },
            totalQuantitySuccess: {
                text: "",
                status: false,
            },
            totalAmountError: {
                text: "",
                status: false,
            },
            totalAmountSuccess: {
                text: "",
                status: false,
            },
            websiteError: {
                text: "",
                status: false,
            },
            websiteSuccess: {
                text: "",
                status: false,
            },
            accountUserIdError: {
                text: "",
                status: false,
            },
            accountUserIdSuccess: {
                text: "",
                status: false,
            }
        }

    },
    mounted() {
        this.fetchData();
        VoucherService.getAll()
            .then((res) => {
                this.voucher = res.data;
            })
            .catch((error) => {
                console.error(error);
            });
        AccountUserService.getAll()
            .then((res) => {
                this.account = res.data;
            })
            .catch((error) => {
                console.error(error);
            });
    },
    methods: {
        makeRandom(lengthOfCode, possible) {
            let text = "";
            for (let i = 0; i < lengthOfCode; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },
        formatPrice(value) {
            return new Intl.NumberFormat('en-US').format(value);
        },
        calculateOrderTotals() {
            this.order.totalQuantity = this.prolist.reduce((sum, product) => sum + parseInt(product.quantity || 0), 0);
            this.order.totalAmount = this.prolist.reduce((sum, product) => sum + parseFloat(product.price || 0), 0);
        },
        addToTempList() {
            // Kiểm tra các trường bắt buộc
            // if (!this.orderDetails.productId || !this.orderDetails.quantity || !this.orderDetails.price) {
            //     alert("Vui lòng điền đầy đủ thông tin sản phẩm.");
            //     return;
            // }
            const selectedProduct = this.product.find(
                (p) => p.id === this.orderDetails.productId
            );
            const productName = selectedProduct ? selectedProduct.name : "Không xác định";
            // Tạo đối tượng sản phẩm mới
            const newProduct = {
                id: null,
                productId: this.orderDetails.productId,
                productName: productName,
                quantity: this.orderDetails.quantity,
                gift: this.orderDetails.gift,
                brand: this.orderDetails.brand,
                price: this.orderDetails.price,
                discountVoucher: this.orderDetails.discountVoucher,
                status: this.orderDetails.status,
                orderId: this.orderDetails.orderId,
            };

            // Thêm vào danh sách tạm thời
            this.prolist.push(newProduct);
            console.log(this.prolist)
            this.calculateOrderTotals();
            // Reset form sau khi thêm sản phẩm
            this.resetOrderDetails();
        },
        resetOrderDetails() {
            this.orderDetails = {
                id: null,
                productId: "",
                quantity: "",
                gift: "",
                brand: "",
                price: "",
                discountVoucher: 0,
                status: true,
                orderId: "",
            };
        },
        removeproductList(i) {
            this.prolist.splice(i, 1);
        },
        fetchData() {
            ProductService.getAll()
                .then((res) => {
                    this.product = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });

            GiftService.getAll()
                .then((res) => {
                    this.gifts = res.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetchProductDetails() {
            if (!this.orderDetails.productId) return;

            this.selectedProduct = this.product.find(item => item.id === this.orderDetails.productId);
            if (this.orderDetails.quantity > 0) {
                this.updateTotalPrice();
            }
            ItemService.getproOrder(this.orderDetails.productId)
                .then((res) => {
                    this.itempro = res.data; // Cập nhật danh sách hậu tố
                    this.itemleght = this.itempro.length;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        updateTotalPrice() {
            // Tính giá tổng: Giá sản phẩm × Số lượng
            this.orderDetails.price = this.orderDetails.quantity * (this.selectedProduct ?.price || 0);
        },
        onSubmitForm() {

            if (this.order.name.length == 0) {
                this.nameError = {
                    text: "FullName cannot be empty!",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.name.length < 6 || this.order.name.length > 50) {
                this.nameError = {
                    text: "FullName must be between 6 and 50 characters",
                    status: true
                }
                this.nameSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.name.length > 6 || this.order.name.length < 50) {
                this.nameSuccess = {
                    text: "Success!",
                    status: true
                }
                this.nameError = {
                    text: "",
                    status: false
                }
            } else {
                this.nameError = {
                    text: "",
                    status: false
                }
            }
            const regex = /^\w+([.-]?\w+)*@[a-z]+([.-]?\w+)*(.\w{2,3})+$/;
            if (this.order.email.length == 0 || this.order.email == '') {
                this.emailError.text = "Email cannot be empty!",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;

            } else if (!regex.test(this.order.email)) {
                this.emailError.text = "Email is not in the correct format !",
                    this.emailError.status = true;
                this.emailSuccess.text = "";
                this.emailSuccess.status = false;
            } else if (regex.test(this.order.email)) {
                this.emailSuccess.text = "Success!!";
                this.emailSuccess.status = true;
                this.emailError.status = false
            } else {
                this.emailError.text = "",
                    this.emailError.status = false
            }
            const regex_phone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
            if (this.order.phone.length == 0) {
                this.phoneError = {
                    text: "Phone cannot be empty",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (!regex_phone.test(this.order.phone)) {
                this.phoneError.text = "Phone is not in the correct format !",
                    this.phoneError.status = true;
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.phone.length != 10) {
                this.phoneError = {
                    text: "Phone must contain 10 characters ",
                    status: true
                }
                this.phoneSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.phone.length < 11 && this.order.phone.length > 9) {
                this.phoneSuccess = {
                    text: "Success!",
                    status: true
                }
                this.phoneError = {
                    text: "",
                    status: false
                }
            } else {
                this.phoneError = {
                    text: "",
                    status: false
                }
            }
            if (this.order.address.length == 0) {
                this.addressError = {
                    text: "address cannot be empty",
                    status: true
                }
                this.addressSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.address.length < 8) {
                this.addressError = {
                    text: "address must contain at least 8 characters ",
                    status: true
                }
                this.addressSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.address.length >= 8) {
                this.addressSuccess = {
                    text: "Success!",
                    status: true
                }
                this.addressError = {
                    text: "",
                    status: false
                }
            } else {
                this.addressError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.paymentMethod.length == 0) {
                this.paymentMethodError = {
                    text: "paymentMethod cannot be empty",
                    status: true
                }
                this.paymentMethodSuccess = {
                    text: "",
                    status: false
                }

            } else if (this.order.paymentMethod.length > 0) {
                this.paymentMethodSuccess = {
                    text: "Success!",
                    status: true
                }
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            } else {
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.paymentMethod.length == 0) {
                this.paymentMethodError = {
                    text: "paymentMethod cannot be empty",
                    status: true
                }
                this.paymentMethodSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.paymentMethod.length > 0) {
                this.paymentMethodSuccess = {
                    text: "Success!",
                    status: true
                }
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            } else {
                this.paymentMethodError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.totalQuantity.length == 0) {
                this.totalQuantityError = {
                    text: "totalQuantity cannot be empty",
                    status: true
                }
                this.totalQuantitySuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.totalQuantity.length > 0) {
                this.totalQuantitySuccess = {
                    text: "Success!",
                    status: true
                }
                this.totalQuantityError = {
                    text: "",
                    status: false
                }
            } else {
                this.totalQuantityError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.totalAmount.length == 0) {
                this.totalAmountError = {
                    text: "totalAmount cannot be empty",
                    status: true
                }
                this.totalAmountSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.totalAmount.length > 0) {
                this.totalAmountSuccess = {
                    text: "Success!",
                    status: true
                }
                this.totalAmountError = {
                    text: "",
                    status: false
                }
            } else {
                this.totalAmountError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.website.length == 0) {
                this.websiteError = {
                    text: "website cannot be empty",
                    status: true
                }
                this.websiteSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.website.length > 0) {
                this.websiteSuccess = {
                    text: "Success!",
                    status: true
                }
                this.websiteError = {
                    text: "",
                    status: false
                }
            } else {
                this.websiteError = {
                    text: "",
                    status: false
                }
            }

            if (this.order.accountUserId.length == 0) {
                this.accountUserIdError = {
                    text: "accountUserId cannot be empty",
                    status: true
                }
                this.accountUserIdSuccess = {
                    text: "",
                    status: false
                }
            } else if (this.order.accountUserId.length > 0) {
                this.accountUserIdSuccess = {
                    text: "Success!",
                    status: true
                }
                this.accountUserIdError = {
                    text: "",
                    status: false
                }
            } else {
                this.accountUserIdError = {
                    text: "",
                    status: false
                }
            }

            if (this.nameSuccess.status == true && this.emailSuccess.status == true && this.phoneSuccess.status == true) {
                // let login = JSON.parse(sessionStorage.getItem("user"));
                this.order.code = this.makeRandom(this.lengthOfCode,this.possible);
                console.log(this.order);
                console.log(this.prolist);
                // if (login.role == 1 || login.role == 2) {
                //     OrderServices.create(this.order)
                //         .then((res) => {
                //             //Perform Success Action
                //             this.ID = res.data.id;
                //             this.order.id = this.ID;
                //             this.order.status = true;
                //             console.log(this.order);
                //             this.$emit("ShowData", this.order);
                //             this.orderDetails.orderId = res.data.id;
                //             for (let i = 0; i < this.prolist.length; i++) {
                //                 const element = this.prolist[i];
                //                 OrderDetailService.create(element)
                //                     .then((res) => {
                //                         //Perform Success Action
                //                         this.ID = res.data.id;
                //                         this.orderDetails.id = this.ID;
                //                         this.orderDetails.status = true;
                //                         console.log(this.orderDetails);
                //                         this.$emit("ShowData", this.orderDetails);
                //                     })
                //                     .catch((error) => {
                //                         // error.response.status Check status code
                //                         console.log(error);
                //                     })
                //                     .finally(() => {
                //                         //Perform action in always
                //                     });
                //             }
                //         })
                //         .catch((error) => {
                //             // error.response.status Check status code
                //             console.log(error);
                //         })
                //         .finally(() => {
                //             //Perform action in always
                //         });
                //     createToast({
                //         title: 'Thêm mới thành công',
                //     }, {
                //         position: 'top-right',
                //         type: 'success',
                //         transition: 'zoom',
                //         showIcon: 'true',
                //         showCloseButton: 'false',
                //     })
                // } else {
                //     alert("Bạn không có quyền sử dụng chức năng này");
                //     createToast({
                //         title: 'Bạn không có quyền này',
                //     }, {
                //         position: 'top-right',
                //         type: 'danger',
                //         transition: 'zoom',
                //         showIcon: 'true',
                //         showCloseButton: 'false',
                //     })
                // }
            } else {
                createToast({
                    title: 'Thêm mới thất bại',
                }, {
                    position: 'top-right',
                    type: 'danger',
                    transition: 'zoom',
                    showIcon: 'true',
                    showCloseButton: 'false',
                })
            }
        },
    },
    watch: {
        "orderDetails.quantity"() {
            // Cập nhật giá tổng khi số lượng thay đổi
            if (this.selectedProduct) {
                this.updateTotalPrice();
            }
        },
        "orderDetails.productId"() {
            // Khi chọn sản phẩm khác, giá tổng cũng thay đổi
            this.fetchProductDetails();
        },
    },
}
</script>
